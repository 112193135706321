$primary-color: #0088ff;
@import "loaders.css/src/animations/line-scale.scss";
@import "~react-confirm-alert/src/react-confirm-alert.css";

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

#react-confirm-alert {
  .react-confirm-alert-body {
    text-align: center;
    box-shadow: 0px 3px 6px #d9d9db;

    h1 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 800;
      text-align: center;
      color: $primary-color;
    }

    .react-confirm-alert-button-group {
      justify-content: center;

      & > button {
        color: white;
        background-color: #ff4600;
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        height: 40px;
        border-radius: 20px;
        padding: 0 20px;
        display: inline-flex;
        align-items: center;

        &:first-child {
          background-color: #bbbbbb;
        }
      }
    }
  }
}

nav.rdt_Pagination > div:first-of-type svg:first-of-type {
  top: 50% !important;
  transform: translateY(-50%);
}

.Toastify__toast {
  border-radius: 20px;
  padding: 10px 20px;
}

.Toastify__toast--info {
  background-color: #0088ff;
}

.Toastify__toast--success {
  background-color: #65c08a;
}

.Toastify__toast--error {
  background-color: #ff4600;
}
